<template>
  <v-row justify="center">
    <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    >

      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nuevo Mix</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="saveMix();">Guardar</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                label="Nombre Mix"
                v-model="namemix"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- collapsed -->
            <v-row v-for="(item, index) in protocolomix" :key="'protocolos_'+index">
              <v-col cols="11" sm="11" md="11">
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header v-slot="{ open }">
                      <v-row no-gutters>
                        <v-col cols="4">Etapa # {{index+1}}</v-col>
                        <v-col
                          cols="8"
                          class="text--secondary"
                        >
                          <v-fade-transition leave-absolute>
                            <v-row
                              v-if="!open"
                              no-gutters
                              style="width: 100%"
                            >
                              <v-col cols="6">Ciclos {{ item.ciclo || '0' }}</v-col>
                            </v-row>
                          </v-fade-transition>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row
                      justify="space-around"
                      no-gutters
                      >
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                          label="Ciclos"
                          type="number"
                          v-model="item.ciclo"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row
                        justify="space-around"
                        no-gutters
                        v-for="(item2, index2) in item.temptiempo"
                        :key="'tiempotemp_'+index2"
                      >
                        <v-col cols="4" sm="4" md="4">
                          <v-text-field
                          label="Temperatura (C°)"
                          type="number"
                          v-model="item2.temperatura"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="3" md="3">
                          <v-text-field
                          label="Tiempo (minutos)"
                          type="number"
                          v-model="item2.tiempo"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="4" sm="1" md="1">
                          <v-btn
                          color="success"
                          outlined
                          fab
                          x-small
                          v-if="index2==0"
                          v-on:click="additemTiempotemp(index)"
                          >
                          <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                          color="error"
                          outlined
                          fab
                          x-small
                          v-if="index2>0"
                          v-on:click="deleteitemTiempotemp(index,index2)"
                          >
                          <v-icon>mdi-minus</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="1" sm="1" md="1">
                <v-btn
                color="success"
                outlined
                fab
                x-small
                v-if="index==0"
                v-on:click="additemProtocolo()"
                >
                <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn
                color="error"
                outlined
                fab
                x-small
                v-if="index>0"
                v-on:click="deleteitemProtocolo(index)"
                >
                <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <!-- end collapsed -->
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Service from '../../services/apis';

export default {
  data: () => ({
    dialog: false,
    namemix: '',
    protocolomix: [
      {
        ciclo: '',
        temptiempo: [
          {
            tiempo: '',
            temperatura: '',
          },
        ],
      },
    ],
  }),
  computed: {
    ...mapState('Ensayos', ['form_data', 'obj_matrices', 'data_ensayo']),
  },
  methods: {
    ...mapMutations('Ensayos', ['setMix']),
    openmodalmix() {
      this.dialog = true;
    },
    additemProtocolo() {
      const newitem = {
        ciclo: '',
        temptiempo: [
          {
            tiempo: '',
            temperatura: '',
          },
        ],
      };
      this.protocolomix.push(newitem);
    },
    deleteitemProtocolo(index) {
      this.protocolomix.splice(index, 1);
    },
    deleteitemTiempotemp(index, index2) {
      this.protocolomix[index].temptiempo.splice(index2, 1);
    },
    additemTiempotemp(index) {
      const newitemtt = {
        tiempo: '',
        temperatura: '',
      };
      this.protocolomix[index].temptiempo.push(newitemtt);
    },
    async saveMix() {
      // console.log(this.protocolomix);
      // console.log(JSON.stringify(this.protocolomix));
      const mix = {
        name: this.namemix,
        protocolos: JSON.stringify(this.protocolomix),
      };
      const data2 = await Service.apiToken('POST', 'new-mix', this.$ls.storage.token, mix);

      // VACIAR
      this.namemix = '';
      this.protocolomix = [
        {
          ciclo: '',
          temptiempo: [
            {
              tiempo: '',
              temperatura: '',
            },
          ],
        },
      ];
      this.data_ensayo.mix.push(data2.data);
      // this.setMix(data2.data);
      // console.log('resp', data2);
      this.dialog = false;
    },
  },
};
</script>
